.news .col-lg-6{
    min-height: 175px;
}

/* @import url("https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;400;500;600;700;800;900&display=swap"); */




.list {
    position: relative;
}

.list h2 {
    color: #fff;
    font-weight: 700;
    letter-spacing: 1px;
    margin-bottom: 10px;
}

.list ul {
    position: relative;
}

.list ul li {
    position: relative;
    left: 0;
    color: #fce4ec;
    list-style: none;
    margin: 4px 0;
    border-left: 2px solid #a6a3a3;
    transition: 0.5s;
    cursor: pointer;
}

.list ul li:hover {
    left: 10px;
}

.list ul li span {
    position: relative;
    padding: 8px;
    padding-left: 12px;
    display: inline-block;
    z-index: 1;
    transition: 0.5s;
}

.list ul li:hover span {
    color: #ffffff;
}

.list ul li:before {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    background: #a6a3a3;
    transform: scaleX(0);
    transform-origin: left;
    transition: 0.5s;
}

.list ul li:hover:before {
    transform: scaleX(1);
}

@media only screen and (min-width: 995px) {
    .news {
        width: 1110px;
    }
}
@media screen and (max-width: 600px) {
    .glitch{
        font-size: 30px !important;
    }
    .long-text{
        font-size: 10px;
    }
}

.glitch {

    font: 700 4em/1 "Oswald", sans-serif;
    letter-spacing: 0;
    
    display: block;
    margin: 0 auto;
    text-shadow: 0 0 80px rgba(12, 12, 12, 0.5);

    /* Clip Background Image */

    background: url(https://i.ibb.co/RDTnNrT/animated-text-fill.png) repeat-x;
    -webkit-background-clip: text;
    background-clip: text;

    /* Animate Background Image */

    -webkit-text-fill-color: transparent;
    -webkit-animation: aitf 80s linear infinite;

    /* Activate hardware acceleration for smoother animations */

    -webkit-transform: translate3d(0, 0, 0);
    -webkit-backface-visibility: hidden;


}
.long-text{
    padding: 20px;
    border-left: 2px solid #a6a3a3;
}
.video1 {
    max-width: 100%;
    padding-top: 150px;
   
    height: max-content;
}
.video2 {
    max-width: 100%;
    padding-top: 100px;
   
    height: max-content;
}
.hero-heading {
    transform: translateY(150px);
    opacity: 0;
}

.hero-heading {
    animation: hero-text-animate 1s 1.5s cubic-bezier(.04, .79, .49, .93) forwards;
    
}

@keyframes hero-text-animate {
    from {
        transform: translateY(150px);
        opacity: 0;
    }

    to {
        transform: translateY(0px);
        opacity: 1;
    }
}

.btab:hover{
    transform: scale(1.1);
}