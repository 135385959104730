
.services .col-11 {
    position: relative;
    overflow: hidden;
    padding: 20px;
    border-radius: 6px;
}

.services .col-lg-6 img {
    width: 50%;
    height: 190px;
    object-fit: contain;
    object-position: right;
}

.services .col-lg-6 .img-out{
    object-fit: cover;
    object-position: center;
    right: 0!important;
}

.services .btn{
    width: 180px;
    height: 42px;
    text-align: center;
    border-radius: 6px!important;
    border: 1px solid #fff;
    color: #000!important;
}

.services .btn:hover{
    color: #fff!important;
    background: transparent!important;
}

@media only screen and (min-width: 995px) {
    .services {
        width: 1150px;
    }
}

.hover1:hover{
animation: mymove1 3s;
opacity: 0.7;

}

@keyframes mymove1 {
    30% {
        transform: scale(1.1);
    
    }
}

.glitch{

    font: 700 4em/1 "Oswald", sans-serif;
    letter-spacing: 0;
    padding: .25em 0 .325em;
    display: block;
    margin: 0 auto;
    text-shadow: 0 0 80px rgba(12, 12, 12, 0.5);

    /* Clip Background Image */

    background: url(https://i.ibb.co/RDTnNrT/animated-text-fill.png) repeat-x;
    -webkit-background-clip: text;
    background-clip: text;

    /* Animate Background Image */

    -webkit-text-fill-color: transparent;
    -webkit-animation: aitf 80s linear infinite;

    /* Activate hardware acceleration for smoother animations */

    -webkit-transform: translate3d(0, 0, 0);
    -webkit-backface-visibility: hidden;
   

}


/* Animate Background Image */

@keyframes aitf {
    0% {
        background-position: 0% 50%;
    }

    100% {
        background-position: 100% 50%;
    }
}


* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}



main {
    display: grid;
    height: 100vh;
    overflow: auto;
    scroll-snap-type: y mandatory;

    >section {
        scroll-snap-align: start;
        scroll-snap-stop: always;

        display: grid;
        place-items: center;
        min-height: 100vh;
    }
}

section {
    color: white;
    padding-block: 30px;
    padding-inline: 30px;

    >div {
        max-width: 960px;
        margin-inline: auto;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        text-align: center;
    }


}

@media (prefers-reduced-motion) {
    [data-animation] {
        animation: none;
    }
}

