.slick-slider .slick-prev {
    left: 35px !important;
    color: black !important;
    z-index: 1;
    background: inherit;
}

.slick-slider .slick-next {
    right: 35px !important;
    color: black !important;
    z-index: 1;
    background: inherit;
}

.slick-prev:before,
.slick-next:before {
    font-size: 30px;
}

.container-fluid {
    padding-right: 0;
    padding-left: 0;
}

.slick-slider img {
    min-height: 280px;
    object-fit: cover;
    object-position: left;

}
.text1 {
    font-weight: 700;
    text-align: center;
    font-size: 100px !important;
    text-transform: uppercase;
    background: linear-gradient(90deg, #f47e7e, #fff, #4607f4);
    letter-spacing: 5px;
    line-height: normal !important;
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent;
    background-repeat: no-repeat;
    background-size: 80%;
    animation: shine 6s linear infinite;
    position    : relative;
  top:200px;
} @keyframes shine {
     0% {
         background-position-x: -500%;
     }

     100% {
         background-position-x: 500%;
     }

 }  


@media screen and (max-width: 650px) {
    .text1{
        font-size: 40px !important;
    }
}