::-webkit-scrollbar {
    width: 10px;
}

::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px rgb(165, 165, 165);
}

::-webkit-scrollbar-thumb {
    background: #7f7f81;
}

.section-2 {
    background-color: #f4feff;
    padding-bottom: 80px;
}

.section-3 {
    background: #f4feff;
}
.section-4{
    padding-bottom: 30px;
    width: 100%;
    background-color: #e0dcdc;
}
.section-5 {
    background-color: #f4feff;
    width: 100% !important;
}

*:focus {
    outline: none;
}
