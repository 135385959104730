.glitch {

    font: 700 4em/1 "Oswald", sans-serif;
    letter-spacing: 0;

    display: block;
    margin: 0 auto;
    text-shadow: 0 0 80px rgba(12, 12, 12, 0.5);

    /* Clip Background Image */

    background: url(https://i.ibb.co/RDTnNrT/animated-text-fill.png) repeat-x;
    -webkit-background-clip: text;
    background-clip: text;

    /* Animate Background Image */

    -webkit-text-fill-color: transparent;
    -webkit-animation: aitf 80s linear infinite;

    /* Activate hardware acceleration for smoother animations */

    -webkit-transform: translate3d(0, 0, 0);
    -webkit-backface-visibility: hidden;


}


.icons{

    font-size: 50px;
    margin: 20px 10px;
}
.insta{
    color: #833ab4;
        color: linear-gradient(to right,
                #833ab4, #fd1d1d, #fcb045)
}


@media only screen and (max-width: 767px) {
    .text01 {
       font-size: small;
    }
    .icons{
    font-size: 20px;
    margin: 0;
}
}