.widgetmini {
    display: none;
    padding: 30px;
}

@media (max-width: 767px) {
    .widgetmini {
        display: block;
    }
}

.accordion-button:focus {
    box-shadow: none;
}

.widgetmini .accordion-button {
    color: white;
    background-color: #263366;
    position: relative;
}

.widgetmini .dropdown-menu {
    width: 100%;
}

.widgetmini .accordion-button::after {
    display: none;
}

.widgetmini .dropdown-toggle::after {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 10px;
}

.widgetmini .accordion-body {
    background-color: #263366eb;
    color: white;
}

.widgetmini .row {
    --bs-gutter-x: 0
}

.widgetmini .MuiInputBase-input {
    height: 37px;
    padding-left: 12px;
}

.minibutton {
    width: 100%;
    height: 50px;
    border: 1px solid #ffda00;
    background-color: #ffda00;
    transition: 0.4s;
}

.minibutton:hover {
    background-color: inherit;
    color: #ffda00;

}

